<template>
  <div class="default lecture-introduce">
    <div
        class="box"
        id="imgBox"
    >
      <!-- 背景图 -->
      <img
          :src="background_img"
          alt=""
          style="max-width:420px"
          class="bg-img"
          id="bg-img"
      >


      <!-- test -->
      <div class="main-box">
         <!-- 头像昵称 -->
         <div class="profile" >
            <div class="headimgurl">
              <img
                v-if="headimgurl"
                :src="headimgurl"
                alt=""
              >
            </div>
            <div class="nickname">{{ nickname }}
            </div>
          </div>

        <!-- logo -->
        <div class="logo">
          <img v-if="logo"  :src="logo" alt="">
        </div>

        <!-- 主标题 -->
        <div
            :class="{'title-box':flag}"
            v-html="title"
        ></div>

        <!-- 副标题 -->
        <div
            :class="{'subhead-box':flag}"
            v-html="subTitle"
        ></div>

        <!-- 文案内容上 -->
        <div :class="{'content-box-top':flag}">
          <div class="tag" v-if="upperListTitle">
            <div class="tag_text">{{ upperListTitle }}</div>
          </div>
          <div
              v-for="(item,index) in contentList_top"
              :key="index"
              :class="['content-item',`content-item${index}`]"
          >
            <div class="icon" v-html="item.icon"></div>
            <div v-html="item.text"></div>
          </div>
        </div>

        <!-- 文案内容下 -->
        <div :class="{'content-box-bottom':flag}" v-if="teacher_introduce.introduce.length!==0">
          <div class="tag" v-if="lowerListTitle">
            <div class="tag_text">{{ lowerListTitle }}</div>
          </div>
          <div class="content">
            <div class="content-text">
              <div
                  v-for="(item,index) in teacher_introduce.introduce"
                  :key="index"
              >
                <span
                    :class="['teacher-name',`teacher-name${index}` ]"
                    v-if="item.name"
                    v-html="item.name"
                ></span>
                <span
                    :class="['teacher-introduce',`teacher-introduce${index}` ]"
                    v-html="item.text"
                ></span>
              </div>
            </div>
            <div class="img-box" v-if="teacher_introduce.img">
              <img
                  :src="teacher_introduce.img"
                  alt=""
              >
            </div>
          </div>
        </div>

          <!-- 底部tips 和 二维码 -->
        <div class="footer">
           <!-- 底部说明左 -->
          <div :class="{'footer-box-left':flag}">
            <div
                v-for="(item,index) in footerList_"
                :key="index"
                class="footerlist-item"
                v-html="item.text"
            >
            </div>
          </div>
          <!-- 底部说明右 -->
          <div 
            v-if="qrcodeRe"
            :class="{'footer-box-right':flag}" 
            :style="`width:${imgSizeWidth*0.3}px;height:${imgSizeWidth*0.3}px;display:flex;justify-content:center;align-items:center;`"
          >
            <VueQr
                v-if="!!qrcode_url" 
                :text="qrcode_url"
                :size="imgSizeWidth*0.20"
                :margin="2"
            ></VueQr>
          </div>

          <!-- 分割线 -->
          <div style="width:100%;position: absolute;box-sizing: border-box;">
            <div style="width:60%; display: inline-block;
              border-top:1px solid #fff;
              box-sizing: border-box;
              position: absolute;
              top: 18px;"></div>
            <div style="width:20%;  border-top: 1px solid red;
              display: inline-block;
              box-sizing: border-box;
              position: absolute;
              top: -15px;
              left: 60%;
              transform: skew(0, -20deg);"> </div>
            <div style="width: 20%;
              border-top: 1px solid #000;
              display: inline-block;
              box-sizing: border-box;
              position: absolute;
              top: -15px;
              right: 0;"></div>
          </div>

        </div>
      </div>

    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import domtoimage from '../utils/domtoimage'
import axios from 'axios'
import VueQr from 'vue-qr'
import html2canvas from 'html2canvas'

export default {
  data() {
    return {
      loading: false,
      background_img: '',
      flag: true,
      headimgurl: '',
      nickname: '',
      qrcode_url: '',
      title: '',
      subTitle: '',
      contentList_top: [],
      teacher_introduce: {
        img: '',
        introduce: []
      },
      footerList_: [],
      upperListTitle: '',
      lowerListTitle: '',
      logo: '',
      imgSizeWidth: 0,
      qrcodeRe: false
    }
  },
  components: {
    VueQr
  },
  created() {
    this.getData()
  },
  mounted() {
    window.vue = this
  },
  methods: {
    jsReturn(text) {
      return text
    },

    //获取数据
    getData() {
      this.loading = true
      // axios.get(`${location.origin}/backend/config/${this.$route.query.config}`)
      axios.get(`https://dev.design.ceba.ceshiren.com/backend/config/${this.$route.query.config}`)
        .then(res => {
          console.log(res);
          if (res.data.data) {
            this.background_img = res.data.data.background_img
            this.title = res.data.data.title
            this.subTitle = res.data.data.subTitle
            this.qrcode_url = res.data.data.qrcode_url
            this.headimgurl = res.data.data.headimgurl
            this.nickname = res.data.data.nickname
            if (res.data.data.contentList_top) this.contentList_top = res.data.data.contentList_top
            if (res.data.data.teacher_introduce) this.teacher_introduce = res.data.data.teacher_introduce
            if (res.data.data.footerList_) this.footerList_ = res.data.data.footerList_
            this.upperListTitle = res.data.data.upperListTitle
            this.lowerListTitle = res.data.data.lowerListTitle
            if (res.data.data.theme) {
              this.createCssLink(res.data.data.theme,'theme')
            }
            if (res.data.data.css) {
              this.createCssLink(res.data.data.css, 'template')
            }
            //  else {
            //   this.createCssLink('default')
            // }

            this.$nextTick(() => {
              let img = document.querySelector('.bg-img')
              console.log(img)
              if (img.complete) {
                this.imgSizeWidth = document.getElementById('bg-img').offsetWidth
                this.qrcodeRe = true
              } else {
                img.addEventListener('load', () => {
                  this.imgSizeWidth = document.getElementById('bg-img').offsetWidth
                  console.log('图片宽度', this.imgSizeWidth);
                  this.qrcodeRe = true
                })
              }
            })
            console.log(this.teacher_introduce);
          }

        })
        .catch(err => {
          this.loading = false
          console.log(err);
        })
    },
    filter(node) {
      return (node.tagName !== 'i');
    },
    domtoimg() {
      console.log('domtoimg');

      var node = document.getElementById('imgBox');
      console.log(node.offsetHeight);
      console.log(node.offsetWidth);
      let width = node.offsetWidth
      let height = node.offsetHeight
      var that = this
      // ios
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        console.log('ios');
        // domtoimage.toSvg(node, { scale: 4, width, height })
        //   .then(function (dataUrl) {
        //     var img = new Image();
        //     img.src = dataUrl;
        //     img.style.cssText =
        //       "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
        //     img.id = "poster-Img-new"
        //     document.body.appendChild(img);
        //     console.log('截图完成');
        //     that.loading = false
        //   })
        //   .catch(function (error) {
        //     console.error('oops, something went wrong!', error);
        //   })
        this.saveHtml2Image()
      }
      // 安卓 || pc = domtoimage
      else {
        console.log('andriod或pc');
        domtoimage.toPng(node, { scale: 4, width, height })
          .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            img.style.cssText =
              "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:99;";
            img.id = "poster-Img-new"
            document.body.appendChild(img);
            console.log('截图完成');
            that.loading = false
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          })
      }

    },

    //将html结构转换为图片
    saveHtml2Image() {

      //转换图片时 将高度归0 避免出现顶部白边
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;

      html2canvas(document.querySelector('#imgBox'), {
        useCORS: true,
        allowTaint: true,
      }).then(async canvas => {
        const img = document.createElement("img");
        let src = canvas.toDataURL("image/jpeg", 0.9)
        img.src = src
        document.body.appendChild(img);
        img.style.cssText =
          "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
        img.id = "poster-Img-new"
        this.loading = false
        console.log('html2cavas');
      });
    },

    //避免重复载入 
    removeRemoteCss(cssId) {
      let removeCssNameId = '#' + cssId
      const linkList = document.querySelectorAll(removeCssNameId)
      console.log(linkList);
      for (var i = 0; i < linkList.length; i++) {
        linkList[i].parentNode.removeChild(linkList[i]);
      }
    },

    // 创建link标签引用远程css
    createCssLink(css_name, cssId) {
      this.removeRemoteCss(cssId)
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.id = cssId
      const cssPath = `https://dev.design.ceba.ceshiren.com/backend/css/${css_name}.css`
      link.href = cssPath
      document.head.appendChild(link)
      console.log('css加载完成');
      // 两个需要载入的css 避免执行两次图片生产代码 第一次不执行
      if (cssId === 'theme') return
      setTimeout(() => {
        this.domtoimg()
      }, 1500)
    },


    //图片加载监控
    imgload_() {
      let imgList = document.getElementsByTagName('img');//图片集合
      let imgCount = imgList.length;//图片总数
      let imgLoad = 0;//加载完成的图片数量
      for (let i = 0; i < imgCount; i++) {
        imgList[i].onload = () => {
          imgLoad++;
          console.log(imgLoad);
          if (imgLoad === imgCount) {
            // img加载完成 do something
            console.log('图片全部加载完成');
            // this.saveHtml2Image()
          }
        }
      }
    },
  }
}
</script>

<style scoped lang="less">

.subhead-box{
  border-bottom: 1px solid #000;
}
</style>